// ** Redux Imports
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'

// ** Axios Imports
import { axiosRequest } from '../http'

export const getHomeData = createAsyncThunk(
    'GET_HOME_DATA',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosRequest({ sub_url: '/admin/adminhomeData', params })
            if (response.status === 200) {
                return response
            }
        } catch (err) {
            if (err.response.data.logout) {
                localStorage.clear()
                sessionStorage.clear()
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    })
export const getProfitData = createAsyncThunk(
    'GET_PROFIT_DATA',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosRequest({ sub_url: '/admin/getprofitlist', params })
            if (response.status === 200) {
                return response
            }
        } catch (err) {
            if (err.response.data.logout) {
                localStorage.clear()
                sessionStorage.clear()
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    })

export const getRevenueData = createAsyncThunk(
    'GET_REVENUE_DATA',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosRequest({ sub_url: '/admin/getrevenuelist', params })
            if (response.status === 200) {
                return response
            }
        } catch (err) {
            if (err.response.data.logout) {
                localStorage.clear()
                sessionStorage.clear()
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    })

export const getUserData = createAsyncThunk(
    'GET_USER_DATA',
    async (params, { rejectWithValue }) => {
        try {
            const response = await axiosRequest({ sub_url: '/admin/getuser_list', params })
            if (response.status === 200) {
                return response
            }
        } catch (err) {
            if (err.response.data.logout) {
                localStorage.clear()
                sessionStorage.clear()
            }
            if (!err.response) {
                throw err
            }
            return rejectWithValue(err.response.data)
        }

    })


const homeInitial = {
    data: {},
    total: 1,
    params: {},
    allData: [],
    profit_data: {
        data: [],
        receiverNames: [],
        page: 1,
        has_more: true,
        count: 0,
        barGraph: [],
        date_filter: {
            status: false,
            data: {}
        },
        loading: false
    },
    revenue_data: {
        data: [],
        page: 1,
        has_more: true,
        receiverNames: [],
        count: 0,
        barGraph: [],
        loading: false,
        date_filter: {
            status: false,
            data: {}
        }
    },
    user_data: {
        data: [],
        page: 1,
        has_more: true,
        count: 0,
        barGraph: [],
        date_filter: {
            status: false,
            data: {}
        },
        loading: false
    },
    loading: false
}
export const homeSlice = createSlice({
    name: 'home',
    initialState: homeInitial,
    reducers: {
        emptyRevenue: (state) => {
            state.revenue_data = homeInitial.revenue_data
        },
        emptyProfit: (state) => {
            state.profit_data = homeInitial.profit_data
        },
        emptyUser: (state) => {
            state.user_data = homeInitial.user_data

        },
        setDateFilter: (state, { payload }) => {
            switch (payload.type) {
                case "user":
                    state.user_data.date_filter.status = payload.status
                    state.user_data.date_filter.data = payload.data
                    break
                case "revenue":
                    state.revenue_data.date_filter.status = payload.status
                    state.revenue_data.date_filter.data = payload.data
                    break
                case "profit":
                    state.profit_data.date_filter.status = payload.status
                    state.profit_data.date_filter.data = payload.data
                    break
            }
        }
    },
    extraReducers: {
        [getHomeData.pending]: (state) => {
            state.loading = true
        },
        [getHomeData.rejected]: (state) => {
            state.loading = false
        },
        [getHomeData.fulfilled]: (state, action) => {
            state.data = action.payload.data.data
            state.loading = false
        },
        [getProfitData.pending]: (state) => {
            state.profit_data.loading = true
        },
        [getProfitData.rejected]: (state) => {
            state.profit_data.loading = false
        },
        [getProfitData.fulfilled]: (state, action) => {
            state.profit_data.data = action.payload.data.data.map((curr, index) => ({ ...curr, id: index + 1 }))
            state.profit_data.receiverNames = action.payload.data.receiverNames
            state.profit_data.has_more = action.payload.data.has_more
            state.profit_data.page = action.payload.data.currentPage
            state.profit_data.count = action.payload.data.count
            state.profit_data.barGraph = action.payload.data.barGraph
            state.profit_data.loading = false
        },
        [getRevenueData.pending]: (state) => {
            state.revenue_data.loading = true
        },
        [getRevenueData.rejected]: (state) => {
            state.revenue_data.loading = false
        },
        [getRevenueData.fulfilled]: (state, action) => {
            state.revenue_data.data = action.payload.data.data.map((curr, index) => ({ ...curr, id: index + 1, receiver_name: curr.receiver_name.toLowerCase().trim() }))
            state.revenue_data.has_more = action.payload.data.has_more
            state.revenue_data.page = action.payload.data.currentPage
            state.revenue_data.receiverNames = action.payload.data.receiverNames
            state.revenue_data.count = action.payload.data.count
            state.revenue_data.barGraph = action.payload.data.barGraph
            state.revenue_data.loading = false
        },
        [getUserData.pending]: (state) => {
            state.user_data.loading = true
        },
        [getUserData.rejected]: (state) => {
            state.user_data.loading = false
        },
        [getUserData.fulfilled]: (state, action) => {
            state.user_data.data = action.payload.data.data.map((curr, index) => ({ ...curr, id: index + 1 }))
            state.user_data.has_more = action.payload.data.has_more
            state.user_data.page = action.payload.data.currentPage
            state.user_data.count = action.payload.data.count
            state.user_data.barGraph = action.payload.data.barGraph
            state.user_data.loading = false
        }
    }
})

export const { emptyProfit, emptyRevenue, emptyUser, setDateFilter } = homeSlice.actions
export default homeSlice.reducer
