// ** Third Party Components
import PropTypes from 'prop-types'
import { useEffect, useState } from 'react'
import Chart from 'react-apexcharts'

// ** Reactstrap Imports
import { Card, CardBody, CardText, Col, Row } from 'reactstrap'
import { kFormatter } from '@utils'
import StatsCard, { AvtarCard } from '../Cards/StatsCard'
import { DollarSign } from 'react-feather'

const stripe_data = [
  {
    value: "sripeFees",
    title: '$0',
    subtitle: 'Total Stripe Fees',
    color: 'light-success',
    icon: <DollarSign size={24} />
  }
]
const TinyChartStats = (props) => {
  // ** Props

  const [seriesData, setSeriesData] = useState([])


  useEffect(() => {
    if (props.chartValues.length) {
      const arr = props.chartValues.map(item => {
        return parseFloat(item).toFixed(2)
      })

      setSeriesData(arr)
    }
  }, [props.chartValues.length])
  const options = {
    chart: {
      toolbar: {
        show: false
      },
      zoom: {
        enabled: false
      }
    },
    grid: {
      borderColor: "#EBEBEB",
      strokeDashArray: 5,
      xaxis: {
        lines: {
          show: true
        }
      },
      yaxis: {
        lines: {
          show: true
        }
      },
      padding: {
        top: -30,
        bottom: -10
      }
    },
    stroke: {
      width: 3
    },
    colors: ["#40c07b"],
    series: [
      {
        data: [
          0,
          1,
          0,
          0,
          5,
          0
        ]
      }
    ],
    markers: {
      size: 2,
      colors: "#00cfe8",
      strokeColors: "#40c07b",
      strokeWidth: 2,
      strokeOpacity: 1,
      strokeDashArray: 0,
      fillOpacity: 1,
      discrete: [
        {
          seriesIndex: 0,
          dataPointIndex: 5,
          fillColor: "#ffffff",
          strokeColor: "#40c07b",
          size: 5
        }
      ],
      shape: "circle",
      radius: 2,
      hover: {
        size: 3
      }
    },
    xaxis: {
      labels: {
        show: false,
        style: {
          fontSize: "13px",
          colors: ["#40c07b"]
        }
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      convertedCatToNumeric: true
    },
    yaxis: {
      show: false,
      style: {
        fontSize: "10px",
        colors: ["#40c07b"]
      },
      axisBorder: {
        show: false
      },
      axisTicks: {
        show: false
      },
      convertedCatToNumeric: true
    },
    tooltip: {
      theme: "dark",
      x: {
        show: true,
        formatter: (val) => {
          return `week: ${val}`
        }
      }
    }
  }
  const series = [
    {
      name: 'Profit',
      data: seriesData
    }
  ]


  useEffect(() => {
    if (props?.Financial_data?.sripeFees) {
      stripe_data[0].title = `$${parseFloat(props?.Financial_data?.sripeFees).toFixed(2)}`
    }

  }, [])

  return (
    <Card className='card-tiny-line-stats mb-0'>
      <CardBody >
        <Row className='pb-50'>
          <Col
            sm={{ size: 12, order: 1 }}
            xs={{ order: 2 }}
            className='d-flex justify-content-between flex-column mt-lg-0 mt-2'
          >
            <div className='session-info mb-1 mb-lg-0'>
              <h2 className='fw-bold mb-25'>{props.prefix}{kFormatter(props.title)}</h2>
              <CardText className='fw-bold mb-2'>{props.label} {props?.tooltip ?  <i class="fa fa-info-circle position-relative" aria-hidden="true"> <span className='tooltip'>{props?.tooltip}</span></i>  : ""}</CardText>
              <h5 className='font-medium-2'>
                <span className='text-success me-50'>{props.percentage > 0 ? "+" : ""}{props.percentage}%</span>
                <span className='fw-normal'>vs last month
                {/* {props?.chartValues?.length} weeks */}
                </span>
              </h5>
            </div>
            {/* <Button color='primary'>View Details</Button> */}
          </Col>
          
          {props?.Financial_data ? <Col
              sm={{ size: 12, order: 1 }}
              xs={{ order: 2 }}
              className='d-flex justify-content-between flex-column mt-lg-0 mb-3'
            >
              <hr />
              <AvtarCard data={stripe_data} cols={{ sm: "12", xl: "6" }} />
              {/* <Button color='primary'>View Details</Button> */}
            </Col> : ""}

          <Col
            sm={{ size: 12, order: 2 }}
            xs={{ order: 1 }}
            className='d-flex justify-content-between flex-column text-end'
          >
            <Chart options={options} series={series} type={"line"} height={200} />
          </Col>
        </Row>
      </CardBody>
    </Card>
  )
}

export default TinyChartStats

// ** PropTypes
TinyChartStats.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  stats: PropTypes.string.isRequired,
  series: PropTypes.array.isRequired
}

// ** Default Props
TinyChartStats.defaultProps = {
  height: 100
}
