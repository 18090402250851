import React, { Fragment } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
// third party
import { Card, Row, Col, Badge, Button } from 'reactstrap'
import BuyersList from '../buyersList'
import ReaderList from '../readerList'
import withReactContent from 'sweetalert2-react-content'
import Swal from 'sweetalert2'

//custom component
import Toaster from "../../components/toaster"
import toast from 'react-hot-toast'
import Model from '../model'
import { axiosRequest } from '../../http'

//redux
import { useDispatch, useSelector } from 'react-redux'
import { approveModal, buyesTable, readersTable, updateContentStatus, updateContentpenStatus } from '../../redux/content_slice'

const SeriesContentCard = ({ keys, data, pending_content, contents }) => {
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const params = useParams()
  const store = useSelector(state => state.content_slice)
  const MySwal = withReactContent(Swal)
  const handleBuyersList = (count) => {
    if (count === 0) {
      return toast.error(<Toaster message="Content yet not baught by anyone" />, { hideProgressBar: true })
    }

    dispatch(
      buyesTable({
        show: true,
        index: keys
      })
    )
  }

  const handleReaderList = (count) => {
    if (count === 0) {
      return toast.error(<Toaster message="Content not bought by anyone yet" />, { hideProgressBar: true })
    }

    dispatch(
      readersTable({
        show: true,
        index: 0
      })
    )
  }

  const handleaproveClick = async (keys, colData, type) => {

    return dispatch(approveModal({
      show: true,
      data: {
        type: "series",
        status: type === "approved" ? "approved" : type === "revert" ? "revert" : "reject",
        content_id: colData.id,
        contents
      }
    }))
    return MySwal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${type === "approved" ? "Approve" : "Reject"}  Content!`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(async function (result) {
      if (result.value) {
        try {
          const response = await axiosRequest({
            sub_url: '/admin/approvecontent',
            data: {
              status: type,
              content_id: colData.id
            }
          })

          if (response.status === 200) {
            dispatch(
              updateContentpenStatus({
                status: type === "approved" ? 1 : 4,
                id: colData.id
              })
            )
            toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
            if (contents.length === 1) {
              navigate(`${process.env.REACT_APP_URL_PREFIX}/pendingcontent`)
            }
            return true

          }

        } catch (err) {
          return toast.success(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
        }
      }
    })
  }

  const handleStatusClick = async (keys, colData) => {
    return MySwal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: `Yes, ${colData.status === 1 ? "Deactivate" : "Activate"}  Content!`,
      customClass: {
        confirmButton: 'btn btn-primary',
        cancelButton: 'btn btn-outline-danger ms-1'
      },
      buttonsStyling: false
    }).then(async function (result) {
      if (result.value) {
        try {
          const response = await axiosRequest({
            sub_url: '/admin/status_config',
            data: {
              user_id: params.id,
              status: colData.status === 1 ? "0" : "1",
              content_id: colData.id
            }
          })

          if (response.status === 200) {
            dispatch(
              updateContentStatus({
                status: colData.status === 1 ? 0 : 1,
                index: keys
              })
            )
            return toast.success(<Toaster message={response.data.message} />, { hideProgressBar: true })
          }

        } catch (err) {
          return toast.success(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
        }
      }
    })
  }
  const handleAction = async (storeData, colData) => {
    const contentInfo = storeData.selectedData
    const content_id = colData.id
    const admin_token = sessionStorage.getItem('auth_token')
    console.log({admin_token})
    const data = {}
    let sub_url
    if (contentInfo.source_type === 1) {
      sub_url = '/login'
      data.email = contentInfo.email
      data.source_type = 1
    } else {
      sub_url = '/sociallogin'
      data.source_id = contentInfo.source_id
      data.source_type = contentInfo.source_type
      data.device_type = 0
    }

    try {
      const response = await axiosRequest({ sub_url, data })
      const url = `series/view/${contentInfo.slug}/${content_id} `
      if (response.status === 200) {
        window.open(`${process.env.REACT_APP_WEB_URL}?t=${response.data.body.token}&u=${response.data.body.logged_in_by}&at=${admin_token}&url=${url}`, '_blank', 'noopener,noreferrer')
      }
    } catch (err) {
      return toast.error(<Toaster message={err.response.data.message} />, { hideProgressBar: true })
    }

  }
  return (
    <Fragment>
      <Card className='p-2'>
        <Row style={{ height: '431px' }}>
          <Col sm={3} xs={12}>
            <div className='coverImage' style={{ height: '100%' }}>
              <img src={data.cover_image} alt={data.title} style={{ height: '100%', maxHeight: '400px' }} />
            </div>
          </Col>
          <Col sm={9} xs={12}>
            <Row className='contentDetails'>
              <Col sm={12} className='contentTitle'>
                <h3 className='d-inline'>{data.title}</h3>
                <hr className='mt-1' />
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Paid / Free : </h6> <span>{data.is_paid === 0 ? "Free" : "Paid"}</span>
              </Col>

              {data.is_paid === 1 &&
                <Col sm={6} className='contentTitle mt-25'>
                  <h6 className='d-inline'>Price : </h6> <span>{data.price}</span>
                </Col>}

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Downloadable : </h6> <span>{data.is_downloadable === 1 || data.is_downloadable === "1" ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Available for subscribers : </h6> <span>{data.is_available === 1 || data.is_available === "1" ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Favorites : </h6> <span>{data.favourite_count}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Comments : </h6> <span>{data.total_comments}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Total Ratings : </h6> <span>{data.total_rating}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Matured Content : </h6> <span>{data.is_mature === 1 ? "Yes" : "No"}</span>
              </Col>

              <Col sm={6} className='contentTitle mt-25'>
                <h6 className='d-inline'>Status : </h6> <Badge className='ms-1' color={data.status === 1 ? 'success' : 'danger'}>{data.status === 1 ? 'Active' : 'Inactive'}</Badge>
              </Col>

              <Col sm={12}>
                <Row>
                  <Col sm={6} className='contentTitle  mt-25'>
                    <div className='d-flex justify-content-between main_info_div'>
                      <h6 className='d-inline mb-0'>Total Buyers :  {data.buyers}</h6>
                      <Badge className='ms-1 view_badge' color={'info'} onClick={() => handleBuyersList(data.buyers)}>{'View details >>'}</Badge>
                    </div>
                  </Col>

                  <Col sm={6}  className='contentTitle mt-25'>
                  <div className='d-flex justify-content-between main_info_div'>
                      <h6 className='d-inline mb-0'>No. of Readers :  {data.visiters}</h6>
                      <Badge className='ms-1 view_badge' color={'info'} onClick={() => handleReaderList(data.visiters)}>{'View details >>'}</Badge>
                    </div>
                  </Col>
                </Row>
              </Col>


            </Row>
            {pending_content === "true" ? <Row style={{ marginTop: '10px' }}>

              <Col sm={12}>
                <>
                  <Button className='ms-auto m-25' color={'success'} outline onClick={() => handleaproveClick(keys, data, "approved")}>
                    Approve
                  </Button>
                  <Button className='ms-auto m-25' color={'danger'} outline onClick={() => handleaproveClick(keys, data, "reject")}>
                    Reject
                  </Button>
                </>
              </Col>
            </Row> : ""}
            <Row style={{ marginTop: '10px' }}>

              <Col sm={12}>
                {(pending_content === "true" || pending_content === "reject") ? "" : <Button className='ms-auto m-25' color={data.status === 0 ? 'success' : 'danger'} outline onClick={() => handleStatusClick(keys, data)}>
                  {data.status === 0 ? "Activate" : "Deactivate"}
                </Button>}
                {pending_content !== "true" ? <Button color={pending_content === "reject" ? 'success' : "info"} className='ms-auto m-25' onClick={() => handleaproveClick(keys, data, "revert")} >
                {pending_content === "reject" ? "Revert Status" : "Make Pending"}
                </Button> : ""}
                {pending_content === "reject" ? "" : <Button color='success' className='ms-auto' onClick={() => handleAction(store, data)}>View Content
                </Button>}
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>
      {((data.type === "content" && store.contentBuyers.model.show) && (store.contentBuyers.model.index === keys)) &&
        <Model>
          <BuyersList data={data} store={store.contentBuyers} />
        </Model>
      }
      {((data.type === "content" && store.contentReaders.model.show) && (store.contentBuyers.model.index === keys)) &&
        <Model>
          <ReaderList data={data} store={store.contentReaders} />
        </Model>
      }
    </Fragment>
  )
}

export default SeriesContentCard